<template>
  <div class="ak-text container-layout-custom" style="max-width: 1980px;">
    <div class="q-pa-xl full-width">
      <div class="row">
        <div class="text-h4 col-md-9">History</div>
      </div>
      <q-list separator class="full-width">
        <div v-if="modules.length > 0"> <!-- doing this 'v-if' to make sure we can get a module. Otherwise we get an error from ModuleListItem component -->
          <module-list-item v-for="log in mediaLogs" :key="log.uid" :module="getModule(log.module_uid)" :bookmarkable="false">
            <template v-if="log.is_finished === '0'" v-slot:topRightAction>
              <q-chip dense square  color="info" class="q-pa-sm">
                Last Watched {{formatDate(log.updated_at)}}
              </q-chip>
            </template>
            <template v-else v-slot:topRightAction>
              <q-chip dense square color="positive" class="q-pa-sm">
                Finished on {{formatDate(log.updated_at)}}
              </q-chip>
            </template>
          </module-list-item>
        </div>
        <div class="row full-width justify-center q-mt-lg">
          <q-pagination v-if="pageMax > 1" v-model="page" :max="pageMax" :max-pages="10" :direction-links="true" />
        </div>
      </q-list>
    </div>
  </div>
</template>

<script>
import { date } from 'quasar'
import ModuleListItem from 'components/ModuleListItem'

export default {
  name: 'HistoryPage',
  components: { ModuleListItem },
  data () {
    return {
      allMediaLogs: [],
      modules: [],
      page: 1,
      perPage: 20
    }
  },
  computed: {
    pageMax () {
      if (this.allMediaLogs.length === 0) return 0
      return Math.ceil(this.allMediaLogs.length / this.perPage)
    },
    mediaLogs () {
      let startIndex = (this.page - 1) * this.perPage
      return this.allMediaLogs.slice(startIndex, startIndex + this.perPage)
    }
  },
  methods: {
    formatDate (pDate) {
      return date.formatDate(pDate, 'MM/DD/YYYY')
    },
    getModule (moduleUid) {
      return this.modules.find(module => module.uid === moduleUid)
    },
    loadModules () {
      let queries = []
      let modules = []
      this.allMediaLogs.forEach(log => {
        queries.push({
          indexName: this.$consts.MODULES_INDEX,
          query: log.module_uid
        })
      })
      this.$moduleSearch.clearCache()
      this.$moduleSearch.search(queries, (err, content) => {
        if (err) throw err
        content.results.forEach(result => {
          result.hits.forEach(hit => {
            modules.push(hit)
          })
        })
      })
      this.modules = modules
    }
  },
  async created () {
    this.$q.loading.show({ message: 'Gathering History' })
    let consolidatedLogs = []
    let res = await this.$axios.get(this.$consts.GET_HISTORY_URL)

    res.forEach(log => {
      if (!consolidatedLogs.find(obj => obj.module_uid === log.module_uid)) {
        consolidatedLogs.push(log)
      }
    })

    this.allMediaLogs = consolidatedLogs
    this.loadModules()
    this.$q.loading.hide()
  }
}
</script>
